import React from 'react';

export const Anchor = React.forwardRef<
  HTMLAnchorElement,
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
>(function Anchor(props, ref) {
  return (
    <a
      {...props}
      ref={ref}
      className={`font-bold cursor-pointer text-pink-500 hover:text-pink-600 hover:underline ${
        props.className || ''
      }`}
    />
  );
});
