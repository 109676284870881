import React from 'react';

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>(function Button(props, ref) {
  return (
    <button
      {...props}
      className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-pink-500 hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-400 disabled:pointer-events-none ${
        props.className || ''
      }`}
      ref={ref}
    />
  );
});

export const ButtonLink = React.forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>(function ButtonLink(props, ref) {
  return (
    <button
      {...props}
      className={`font-bold cursor-pointer text-pink-500 hover:text-pink-600 hover:underline ${
        props.className || ''
      }`}
      ref={ref}
    />
  );
});

export const ButtonOutline = React.forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>(function ButtonLink(props, ref) {
  return (
    <button
      {...props}
      className={`border border-dashed border-pink-500 hover:bg-pink-500 hover:text-white rounded py-2 px-4 transition-colors ${
        props.className || ''
      }`}
      ref={ref}
    />
  );
});
