import React from 'react';
import { Button } from '@components/Button';
import { FAQs } from '@components/FAQs';
import { Anchor } from '@components/Anchor';
import { useRouter, NextRouter } from 'next/router';
import { UserAccessTokenLocalRepository } from '@lib/user/access/UserAccessToken';
import { LoadingScreen } from '@components/Loading';
import { GetServerSideProps } from 'next';
import { GetServerSidePropsHandler } from '@lib/ApiHandler';

interface Props {
  redirectTo: string;
}

const Signin: React.FC<Props> = ({ redirectTo }) => {
  const [status, setStatus] = React.useState<'loading' | 'ready'>('loading');
  const router = useRouter();
  const routerReplaceRef = React.useRef<typeof router['replace']>(
    router.replace
  );

  if (router.replace !== routerReplaceRef.current) {
    routerReplaceRef.current = router.replace;
  }

  React.useEffect(() => {
    UserAccessTokenLocalRepository.refresh()
      .then(() => {
        routerReplaceRef.current(redirectTo);
      })
      .catch(() => {
        setStatus('ready');
      });
  }, [routerReplaceRef, redirectTo, setStatus]);

  if (status === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col justify-center flex-1 space-y-8">
        <h1 className="text-center text-3xl font-bold">
          Let&apos;s get started!
        </h1>
        <div className="text-center space-y-2">
          <Button
            onClick={() => {
              performNotionSigninInSeparateWindow(router, redirectTo);
            }}
          >
            Sign in with Notion
          </Button>
          <p className="text-xs">
            By signing in, you agree to our Terms and Privacy Policy.
          </p>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        <FAQs
          questions={[
            {
              title: 'Why do I need to sign in with Notion?',
              answer: (
                <>
                  <p>
                    NotionExtensions uses notion.so&apos;s{' '}
                    <Anchor
                      href="https://developers.notion.com/"
                      target="_blank"
                    >
                      official API
                    </Anchor>{' '}
                    to offer you helpful tools such as recurring events or bulk
                    edits.
                  </p>
                  <p>
                    In order to be able to use it, you first have to authorize
                    us to access your workspace.
                  </p>
                </>
              ),
            },
            {
              title: 'Why does NotionExtensions need so many permissions?',
              answer: (
                <>
                  <p>
                    All the tools offered by NotionExtensions need read and edit
                    permissions to be able to function.
                    <br />
                    For example, in order to create a recurring event,
                    NotionExtensions needs to be able to create new pages in
                    your workspace.
                    <br />
                    All in all, remember that you decide what pages we have
                    access to when signing in.
                  </p>
                  <p>
                    Finally, we request to &quot;View workspace users and their
                    emails&quot; just in case we need to contact you (e.g. If an
                    extension suddenly stops working and requires some input
                    from you).
                    <br />
                    We will <b>NOT</b> read nor use the emails of any other
                    workspace user but yours.
                  </p>
                </>
              ),
            },
            {
              title: "I'm concerned about privacy",
              answer: (
                <>
                  We are too! That&apos;s why we store as little information as
                  possible from you and your notion.so workspace.
                  <br /> Most of the data NotionExtension&apos;s stores is just
                  IDs of notion database and pages.
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Signin;

export const getServerSideProps: GetServerSideProps<Props> =
  GetServerSidePropsHandler(async ({ query }) => {
    const redirectTo =
      (Array.isArray(query.redirectTo)
        ? query.redirectTo[0]
        : query.redirectTo) || '/';

    return { props: { redirectTo } };
  });

function performNotionSigninInSeparateWindow(
  router: NextRouter,
  redirectTo: string
) {
  const popup = window.open(`${location.origin}/notion/signin`);

  if (!popup) {
    return;
  }

  const callback = async (e: { data: any }) => {
    if (e.data?.type !== 'notion_signin_callback') {
      return;
    }

    switch (e.data?.status) {
      case 'success':
        await UserAccessTokenLocalRepository.set(e.data.accessToken);
        popup.close();
        router.replace(redirectTo);
        break;
      default:
        break;
    }

    window.removeEventListener('message', callback);
  };

  window.addEventListener('message', callback);
}
