import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

type Question = {
  title: string;
  answer: React.ReactNode;
};

export const FAQs: React.FC<{ questions: Question[] }> = ({ questions }) => {
  return (
    <div className="space-y-2">
      {questions.map((question) => (
        <div key={question.title}>
          <Disclosure>
            <Disclosure.Button className="px-4 py-2 bg-pink-100 hover:bg-pink-200 text-pink-900 rounded text-sm flex w-full">
              {({ open }) => (
                <>
                  <span className="flex-1">{question.title}</span>
                  {open ? (
                    <ChevronUpIcon className="w-5 h-5" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5" />
                  )}
                </>
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500 text-sm px-4 pt-4 pb-2 space-y-2">
              {question.answer}
            </Disclosure.Panel>
          </Disclosure>
        </div>
      ))}
    </div>
  );
};
